.footer-container{
    position: relative;
}
.footer-container>hr{
    border: 1px solid var(--Lightgray);
}
.footer{
    padding: 1 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
align-items: center;
justify-content: center;
height: 20rem;
}
.social-link{
    display: flex;
    gqp: 4rem;
}
.social-link>img{

    width: 2rem;
    height: 2rem;
    cursor: pointer;
}
.logo-f>img{
    width: 10rem;
}
.blur-f-1{
bottom: 0;
right: 15rem;
width: 26rem;
height: 12rem;filter: blur(200px);
background: red;
}

.blur-f-2{
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;filter: blur(200px);
    background: rgba(255, 0, 170, 0.637);
    }